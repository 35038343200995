import React from 'react';
import { FloatButton, Popconfirm, Popover } from 'antd';
import { OrderDocuments, OrderDocumentsChangeModal, OrderTTN } from '../';
import { IOM_Order } from 'main/services/OrderManager';
import { TruckOutlined, FileTextOutlined, FileAddOutlined, MailOutlined } from '@ant-design/icons';
import { OrderApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';

interface IOrderDetailButtons {
  order: IOM_Order;
  onChange: () => void;
}

export const OrderDetailButtons: React.FC<IOrderDetailButtons> = ({ order, onChange }) => {
  const handleChange = (): void => {
    onChange();
  };

  const handleSendInvoice = (): void => {
    OrderApi.sendInvoice(order.id).then((res) => {
      handleChangeStatus<Record<string, string>>(res);
    });
  };

  return (
    <FloatButton.Group shape="circle" target={'header'}>
      <OrderTTN order={order} onChange={handleChange}>
        <FloatButton badge={{ count: '!' }} icon={<TruckOutlined/>} type={'primary'}/>
      </OrderTTN>
      <OrderDocumentsChangeModal order_id={order.id} onChange={handleChange}>
        <FileAddOutlined />
      </OrderDocumentsChangeModal>
      <Popover
        content={<OrderDocuments documents={order.documents ?? []}/>}
        placement={'topLeft'}
        trigger="click"
      >
        <FloatButton tooltip={'Документи'} badge={{ count: order?.documents?.length }} icon={<FileTextOutlined/>} type={'primary'}/>
      </Popover>
      <Popconfirm
        title="Надіслати інвойс клієнту"
        description="Ви впевнені що хочете надіслати ?"
        onConfirm={handleSendInvoice}
        // onCancel={cancel}
        okText="Так"
        cancelText="Ні"
      >
        <FloatButton tooltip={'Надіслати інвойс клієнту'} icon={<MailOutlined />} type={'primary'}/>
      </Popconfirm>
    </FloatButton.Group>
  );
};
