import React, { useState } from 'react';
import { Badge, Button, Card, Divider, Drawer, Form, Space } from 'antd';
import { FormChanges, IBaseGetAllParams, IFilter } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { FilterOutlined } from '@ant-design/icons';

interface IFiltersProps {
  filters: IFilter[];
  onChange: (filters: IBaseGetAllParams) => void;
  loading: boolean;
}

const gridStyle: React.CSSProperties = {
  width: '100%',
  padding: '15px',
};

export const Filters: React.FC<IFiltersProps> = ({ filters, onChange, loading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [counter, setCounter] = useState<number | undefined>();
  const [form] = Form.useForm();
  const fields = filters.map((filter) => (
    <Card.Grid key={filter.name} style={gridStyle}>
      <Form.Item name={filter.name}
                 label={filter.title}
      >
        {filter.field}
      </Form.Item>
    </Card.Grid>
  ));

  const handleResetForm = (): void => {
    setCounter(undefined);
    onChange({ clear_filters: true });
    form.resetFields();
  };

  const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges): void => {
    setCounter(Object.values(allValues).reduce((acc, value) => {
      if (value !== undefined) acc.push(value);
      return acc;
    }, [] as (string | number | boolean)[])?.length ?? undefined);
    onChange(allValues);
  });

  const handleClose = (): void => {
    setIsOpen(false);
  };

  const handleOpen = (): void => {
    setIsOpen(true);
  };

  return (
    <>
    <Badge count={counter}>
      <Button type={'primary'} onClick={handleOpen}>
        <FilterOutlined /> Фільтри
      </Button>
    </Badge>
      <Drawer title={'Фільтр:'} open={isOpen} onClose={handleClose}>
        <Form layout={'vertical'} onValuesChange={handleChange} form={form} disabled={loading}>
          <Space direction={'vertical'}>
            <Card size={'small'}>
              {fields}
            </Card>
            <Button htmlType="reset" onClick={handleResetForm}>Скинути</Button>
          </Space>
        </Form>
      </Drawer>
      <Divider/>
    </>
  );
};
