import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ICustomerPartnerOrder } from 'main/interfaces';
import { usePagination } from 'src/hook';
import { CustomerApi } from 'src/api';
import { TableProps } from 'antd';
import { Formatter } from 'src/helpers';
import { Table } from 'src/base-component';
import { CustomerPartnerOrdersProducts } from './CustomerPartnerOrdersProducts';
import { IBaseGetAllParams } from 'src/interfaces';

interface ICustomerPartnerOrdersProps {
  customer_id: string;
}

export const CustomerPartnerOrders: React.FC<ICustomerPartnerOrdersProps> = ({ customer_id }) => {
  const [data, setData] = useState<ICustomerPartnerOrder[]>();
  const [loading, setLoading] = useState(true);
  const { pagination, handlePagination } = usePagination<ICustomerPartnerOrder>();
  const formattedData = data?.map((product) => ({ ...product, key: Number(product.id) }));
  const paginationParams = useMemo(() => ({ page: pagination?.current ?? 1, pageSize: pagination?.pageSize ?? 10 }), [pagination]);

  const getCustomers = useCallback((params: IBaseGetAllParams): void => {
    setLoading(true);
    CustomerApi.getPartnerOrders(customer_id, Number(params?.page), Number(params?.pageSize)).then((res) => {
      setData(res?.data?.rows ?? []);
      handlePagination(res, getCustomers, Number(params?.page), Number(params?.pageSize));
      setLoading(false);
    });
  }, [customer_id, handlePagination]);

  const columns: TableProps<ICustomerPartnerOrder>['columns'] = [
    {
      title: '№ Тестування',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Сума',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => Formatter.toMoney(record?.amount ?? '', true) + ' ' + 'грн',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
  ];

  const handleExpandable = (record: ICustomerPartnerOrder): ReactNode => {
    return <CustomerPartnerOrdersProducts customer_order_products={record?.order_products ?? []} />;
  };

  useEffect(() => {
    if (!data) {
      getCustomers(paginationParams);
    }
  }, [getCustomers, paginationParams, data]);

  return (
    // @ts-ignore
    <Table columns={columns} data={formattedData} loading={loading} onExpandable={handleExpandable} pagination={pagination}/>
  );
};
