import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import { Table as AntTable, TablePaginationConfig, TableProps } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams, IFilter } from 'src/interfaces';
import { Filters } from 'src/base-component';
import type { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/es/table/interface';

interface ITableProps<T> {
  getColumns: (getData: (params?: Record<string, string | number | boolean | undefined>) => void) => TableProps<T>['columns']
  onExpandable?: (record: T) => ReactNode;
  getData: (params: IBaseGetAllParams) => Promise<ApiBaseResponse<ApiWithPaginationResponse<T>> | undefined>;
  filtersTypes?: IFilter[];
}

export const Table = <T, >({ getColumns, getData, filtersTypes, onExpandable }: ITableProps<T>): ReactElement<ITableProps<T>> => {
  const [data, setData] = useState<T[]>();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState<TablePaginationConfig | undefined>();
  const [sort, setSort] = useState<string>();
  const [filters, setFilters] = useState<IBaseGetAllParams>({});

  const handleData = useCallback((params?: Record<string, string | number | boolean | undefined>): void => {
    const requestParams = {
      page: pagination?.current ?? 1,
      limit: pagination?.pageSize ?? 50,
      sort: params?.sort ?? sort,
      ...(params?.clear_filters ? {} : filters),
      ...(params?.clear_filters ? {} : (params ?? {}))
    };
    setLoading(true);
    getData(requestParams).then((res) => {
      // @ts-ignore
      setData(res?.data?.rows?.map((item) => ({ ...item, key: (item?.id ?? item?.id_product ?? item?.createdAt) as keyof T })) ?? []);
      setLoading(false);
      setPagination({
        pageSize: res?.data?.limit,
        total: res?.data?.count,
        current: res?.data?.page
      });
      setSort(res?.data?.sort);
    });
  }, [sort, getData, pagination, filters]);

  const columns = getColumns(handleData);

  const handleChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<AnyObject> | SorterResult<AnyObject>[], extra: TableCurrentDataSource<AnyObject>): void => {
    const requestParams = {
      pageSize: pagination?.pageSize ?? 50,
      page: pagination?.current ?? 1,
    };

    if (Array.isArray(sorter)) {
      handleData({ ...requestParams });
    } else if (sorter?.columnKey && sorter?.order) {
      handleData({ ...requestParams, sort: `${sorter.columnKey},${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` });
    } else {
      handleData({ ...requestParams });
    }
  };

  const handleFilter = useCallback((filters?: Record<string, string | number | boolean | undefined>) => {
    if (!filters?.clear_filters) setFilters(filters);
    handleData({ ...filters, page: 1 });
  }, [handleData]);

  useEffect(() => {
    if (data === undefined) handleData();
  }, [data, handleData, getData]);

  return (
    <>
      {filtersTypes && <Filters filters={filtersTypes} onChange={handleFilter} loading={loading} />}
      <div className={'mb-table'}>
        <AntTable columns={columns as AnyObject[]}
                  dataSource={data as AnyObject[]}
                  loading={loading}
                  pagination={{ ...pagination }}
                  onChange={handleChange}
                  expandable={{
                    expandedRowRender: onExpandable,
                    rowExpandable: (record) => !!onExpandable,
                  }}
        />
      </div>
    </>
  );
};
