import { BaseApi } from '.';
import {
    IProduct,
    IProductAttribute,
    IProductCategory,
    IProductDiscount, IProductDocument, IProductImage,
    IProductInfo, IProductRecommend,
    IProductTag
} from 'main/interfaces';
import { ApiBaseResponse, ApiWithPaginationResponse, BaseApiPostData, IBaseGetAllParams } from 'src/interfaces';

class ProductApi extends BaseApi {
    constructor() {
        super('/product');
    }

    async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<IProduct>> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<IProduct>>>(`?page=${params?.page ?? 1}&limit=${params?.limit ?? 100}${params?.name ? '&info=name,' + params?.name + ',LIKE' : ''}${params?.model ? '&model=' + params?.model : ''}${params?.id ? '&id=' + params?.id : ''}`))?.data);
    }

    async getOne(id: string): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProduct>>('/' + id))?.data);
    }

    async create(data: BaseApiPostData): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProduct>>('/', data))?.data);
    }

    async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IProduct>>('/' + id, data))?.data);
    }

    async delete(id: string): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<Record<string, string>>>('/' + id))?.data);
    }

    async copy(id: string): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<Record<string, string>>>('/' + id + '/copy'))?.data);
    }

    async getInfo(id: string): Promise<ApiBaseResponse<IProductInfo[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductInfo[]>>('/' + id + '/info'))?.data);
    }

    async updateInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.PUT<ApiBaseResponse<IProduct>>('/' + id + '/info', data))?.data);
    }

    async createInfo(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProduct>>('/' + id + '/info', data))?.data);
    }

    async getCategories(product_id: string): Promise<ApiBaseResponse<IProductCategory[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductCategory[]>>('/' + product_id + '/category'))?.data);
    }

    async addCategory(product_id: number, category_id: number): Promise<ApiBaseResponse<IProductCategory> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductCategory>>('/' + product_id + '/category/' + category_id))?.data);
    }

    async deleteCategory(product_id: number, category_id: number): Promise<ApiBaseResponse<IProductCategory> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductCategory>>('/' + product_id + '/category/' + category_id))?.data);
    }

    async getTags(product_id: string): Promise<ApiBaseResponse<IProductTag[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductTag[]>>('/' + product_id + '/tag'))?.data);
    }

    async addTag(product_id: number, tag_id: number): Promise<ApiBaseResponse<IProductTag> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductTag>>('/' + product_id + '/tag/' + tag_id))?.data);
    }

    async deleteTag(product_id: number, tag_id: number): Promise<ApiBaseResponse<IProductTag> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductTag>>('/' + product_id + '/tag/' + tag_id))?.data);
    }

    async getRecommends(product_id: string): Promise<ApiBaseResponse<IProductRecommend[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductRecommend[]>>('/' + product_id + '/recommend'))?.data);
    }

    async addRecommends(product_id: number, recommend_id: number): Promise<ApiBaseResponse<IProductRecommend> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductRecommend>>('/' + product_id + '/recommend/' + recommend_id))?.data);
    }

    async deleteRecommends(product_id: number, recommend_id: number): Promise<ApiBaseResponse<IProductRecommend> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductRecommend>>('/' + product_id + '/recommend/' + recommend_id))?.data);
    }

    async getAttributes(product_id: string): Promise<ApiBaseResponse<IProductAttribute[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductAttribute[]>>('/' + product_id + '/attribute'))?.data);
    }

    async addAttribute(product_id: number, attribute_id: number, value_id: number): Promise<ApiBaseResponse<IProductAttribute> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductAttribute>>('/' + product_id + '/attribute/' + attribute_id, { id_attribute_value: value_id }))?.data);
    }

    async deleteAttribute(product_id: number, attribute_id: number): Promise<ApiBaseResponse<IProductAttribute> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductAttribute>>('/' + product_id + '/attribute/' + attribute_id))?.data);
    }

    async getDiscounts(product_id: string): Promise<ApiBaseResponse<IProductDiscount[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductDiscount[]>>('/' + product_id + '/discount'))?.data);
    }

    async addDiscount(product_id: number, discount: number): Promise<ApiBaseResponse<IProductDiscount> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductDiscount>>('/' + product_id + '/discount', { discount }))?.data);
    }

    async getPrice(id: string): Promise<ApiBaseResponse<IProduct> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProduct>>('/' + id + '/price'))?.data);
    }

    async deleteDiscount(product_id: number): Promise<ApiBaseResponse<IProductDiscount> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductDiscount>>('/' + product_id + '/discount'))?.data);
    }

    async getImages(product_id: string): Promise<ApiBaseResponse<IProductImage[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductImage[]>>('/' + product_id + '/image'))?.data);
    }

    async addImage(product_id: number, discount: number): Promise<ApiBaseResponse<IProductImage> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductImage>>('/' + product_id + '/image'))?.data);
    }

    async deleteImage(product_id: number, image_id: number): Promise<ApiBaseResponse<IProductImage> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductImage>>('/' + product_id + '/image/' + image_id))?.data);
    }

    async getDocuments(product_id: string): Promise<ApiBaseResponse<IProductDocument[]> | undefined> {
        return this.errorHandler((await this.GET<ApiBaseResponse<IProductDocument[]>>('/' + product_id + '/document'))?.data);
    }

    async addDocument(product_id: number, discount: number): Promise<ApiBaseResponse<IProductDocument> | undefined> {
        return this.errorHandler((await this.POST<ApiBaseResponse<IProductDocument>>('/' + product_id + '/document'))?.data);
    }

    async deleteDocument(product_id: number, document_id: number): Promise<ApiBaseResponse<IProductDocument> | undefined> {
        return this.errorHandler((await this.DELETE<ApiBaseResponse<IProductDocument>>('/' + product_id + '/document/' + document_id))?.data);
    }
}

export const productApi = new ProductApi();
