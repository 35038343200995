import React from 'react';
import { Tabs, TabsProps } from 'antd';
interface IWithLanguageTabsProps {
    language?: number;
}
export function withLanguageTabs<P>(WrappedComponent: React.FC<P & IWithLanguageTabsProps>) {
    return (props: P) => {
        const languages = [
            { id: 1, code: 'ua', title: 'UKR' },
            { id: 2, code: 'en', title: 'ENG' },
            { id: 3, code: 'ru', title: 'RUS' },
        ];
        const tabs: TabsProps['items'] = languages.map((language) => ({
            key: language.code,
            label: language.title,
            children: <WrappedComponent language={language.id} {...props}/>
        }));
        return <Tabs type={'card'} items={tabs} defaultActiveKey="ua"/>;
    };
};
