import React, { useEffect, useState } from 'react';
import { Button, DatePicker, FloatButton, Form, Input, Modal, Select, Space, Switch } from 'antd';
import { OrderApi, WarehouseApi } from 'src/api';
import { handleChangeStatus } from 'src/helpers';
import { IWarehouse } from 'main/interfaces';

interface IOrderDocumentsChangeModalProps {
  order_id: number;
  children: React.ReactNode;
  onChange: () => void;
}

export const OrderDocumentsChangeModal: React.FC<IOrderDocumentsChangeModalProps> = ({ order_id, children, onChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [warehouses, setWarehouses] = useState<IWarehouse[]>();

  const handleShow = (): void => {
    setIsModalOpen(true);
  };

  const handleClose = (): void => {
    setIsModalOpen(false);
  };

  const handleFinish = (values: Record<string, string | boolean | number>): void => {
    setLoading(true);
    OrderApi.changeInvoice(order_id, values).then((res) => {
      handleChangeStatus<Record<string, string>>(res);
      if (res?.status) form.resetFields();
      onChange();
      setLoading(false);
    });
  };

  useEffect(() => {
    if (!warehouses) {
      setLoading(true);
      WarehouseApi.getAll({ type: 'STORE,INFO' }).then((res) => {
        setWarehouses(res?.data?.rows ?? []);
        setLoading(false);
      });
    }
  }, [warehouses]);

  return (
    <>
      <Modal title={'Створити або змінити накладну'} footer={[]} open={isModalOpen} loading={loading} onCancel={handleClose}>
        <Form
          name="validate_other"
          onFinish={handleFinish}
          layout={'vertical'}
          form={form}
          disabled={loading}
        >
          <Form.Item name="doc_type"
                     label="Документ"
                     rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                     hasFeedback
          >
            <Select>
              <Select.Option value={'invoice'}>Інвойс</Select.Option>
              <Select.Option value={'receipt'}>Накладна</Select.Option>
              <Select.Option value={'transports'}>Транспортна</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item name="date"
                     label="Дата"
                     hasFeedback
          >
            <DatePicker style={{ width: '100%' }}/>
          </Form.Item>
          <Form.Item name="receipt_type_name"
                     label="Тип (назва) договору"
                     hasFeedback
          >
            <Input/>
          </Form.Item>
          <Form.Item name="manager_name"
                     label="Імʼя підписантa"
                     hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item name="storage"
                     label="Адреса складу"
                     hasFeedback
          >
            <Select placeholder="Оберіть варіант із списку">
              {warehouses?.map((warehouse) => (<Select.Option value={warehouse.address} key={warehouse.id}>{warehouse.address}</Select.Option>))}
            </Select>
          </Form.Item>
          <Form.Item name="show_address_check"
                     label="Не показувати адресу"
                     hasFeedback
          >
            <Switch />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Відправити
              </Button>
              <Button htmlType="reset">Скинути</Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <FloatButton tooltip={'Створити або змінити накладну'} icon={children} type={'primary'} onClick={handleShow} />
    </>
  );
};
