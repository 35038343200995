import React, { ReactNode } from 'react';
import { IWarehouseLot } from 'main/interfaces';
import { WarehouseApi } from 'src/api';
import { Input, TableProps } from 'antd';
import { Table } from 'src/base-component/Table/_Table';
import { WarehouseLotsProducts } from './WarehouseLotsProducts';
import { ROUTES } from 'src/constants';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams } from 'src/interfaces';

interface IWarehouseLotsProps {
  warehouse_id: string;
  pageSize?: number;
}

export const WarehouseLots: React.FC<IWarehouseLotsProps> = ({ warehouse_id, pageSize }) => {
  const getProducts = (params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<IWarehouseLot>> | undefined> => {
    return WarehouseApi.getProducts({ ...params, id_warehouse: warehouse_id });
  };

  const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<IWarehouseLot>['columns'] => [
    {
      title: 'ID',
      dataIndex: 'id_product',
      key: 'id_product',
    },
    {
      title: 'Модель',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Назва',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <a href={`${ROUTES.CATALOG_PRODUCTS}/edit/${record.id_product}`}>{record.name}</a>
    },
  ];

  const filters = [
    {
      name: 'name',
      title: 'Назва товару',
      field: <Input />
    },
    {
      name: 'model',
      title: 'Модель товару',
      field: <Input />
    }
  ];

  const handleExpandable = (record: IWarehouseLot): ReactNode => {
    return <WarehouseLotsProducts products={record?.rows ?? []} />;
  };

  return (
    <Table getColumns={getColumns} getData={getProducts} filtersTypes={filters} onExpandable={handleExpandable}/>
  );
};
