import React, { SyntheticEvent, useState } from 'react';
import { InputNumber } from 'antd';
import { BaseApiPostData } from 'src/interfaces';
import { IOM_OrderProduct } from 'main/services/OrderManager';

interface IOrderEditProductsPriceProps {
  product: IOM_OrderProduct;
  onChange: (product: BaseApiPostData) => void;
}

export const OrderEditProductsPrice: React.FC<IOrderEditProductsPriceProps> = ({ product, onChange }) => {
  const [price, setPrice] = useState<number>(product.price);

  const handleChangePrice = (value: number | null): void => {
    setPrice(value ?? 0);
  };

  const handleChaneProduct = (event: SyntheticEvent<HTMLInputElement>): void => {
    onChange({ id: product.id, count: product.count, price: event.currentTarget.value ?? 0 });
  };

  return <InputNumber value={price} onBlur={handleChaneProduct} onChange={handleChangePrice} addonAfter={'грн'} style={{ minWidth: '160px' }} />;
};
