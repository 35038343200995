import { BaseApi } from '.';
import { ApiBaseResponse, ApiWithPaginationResponse, IBaseGetAllParams, BaseApiPostData } from 'src/interfaces';
import {
  ICustomer,
  ICustomerCompany,
  ICustomerPartnerBonus,
  ICustomerPartnerInfo,
  ICustomerPartnerOrder
} from 'main/interfaces';

class CustomerApi extends BaseApi {
  constructor() {
    super('/customer');
  }

  async getAll(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICustomer>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICustomer>>>(`?page=${params?.page ?? 1}${params?.name ? '&info=name,' + params.name + ',LIKE' : ''}`))?.data);
  }

  async getOne(id: string): Promise<ApiBaseResponse<ICustomer> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ICustomer>>('/' + id))?.data);
  }

  async update(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICustomer> | undefined> {
    return this.errorHandler((await this.PUT<ApiBaseResponse<ICustomer>>('/' + id, data))?.data);
  }

  async create(data: BaseApiPostData): Promise<ApiBaseResponse<ICustomer> | undefined> {
    return this.errorHandler((await this.POST<ApiBaseResponse<ICustomer>>('/', data))?.data);
  }

  async getClients(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICustomer>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICustomer>>>(`${this._getFilters(params)}`))?.data);
  }

  async getPartnerOrders(customer_id: string, page?: number, pageSize?: number): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerOrder>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerOrder>>>(`/${customer_id}/partner-orders?page=${page ?? 1}&limit=${pageSize ?? 10}`))?.data);
  }

  async getPartnersOrders(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerOrder>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerOrder>>>(`/partner-testing-products${this._getFilters({ ...params, sort: 'id,DESC' })}`))?.data);
  }

  async getPartnerBonuses(params: IBaseGetAllParams): Promise<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerBonus>> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ApiWithPaginationResponse<ICustomerPartnerBonus>>>(`/partner-bonus?page=${params?.page ?? 1}&limit=${params?.pageSize ?? 10}`))?.data);
  }

  async updatePartnerBonus(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<Record<string, string>> | undefined> {
    return this.errorHandler((await this.PUT<ApiBaseResponse<Record<string, string>>>('/partner-bonus/' + id, data))?.data);
  }

  async registrationPartner(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICustomerPartnerInfo> | undefined> {
    return this.errorHandler((await this.POST<ApiBaseResponse<ICustomerPartnerInfo>>('/partner/' + id, data))?.data);
  }

  async registrationCompany(id: number, data: BaseApiPostData): Promise<ApiBaseResponse<ICustomerCompany> | undefined> {
    return this.errorHandler((await this.POST<ApiBaseResponse<ICustomerCompany>>('/company/' + id, data))?.data);
  }
}

export const customerApi = new CustomerApi();
