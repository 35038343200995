import React, { useState } from 'react';
import { withLanguageTabs } from 'src/hoc';
import { Col, Form, Input, Row } from 'antd';
import { TextEditor } from 'src/base-component';
import { IProductInfo } from 'main/interfaces';
import { getProductInfoByLang, handleFormChanges, handleTextEditorChange } from 'src/helpers';
import { FormChanges } from 'src/interfaces';

interface IProductMainInfoForm {
    language?: number;
    info?: IProductInfo[] | undefined;
    onCreate: (changes: Record<string, string | number | boolean>, language: number) => void;
    onUpdate: (changes: Record<string, string | number | boolean>, language: number) => void;
}

const ProductMainInfoForm: React.FC<IProductMainInfoForm> = ({ language, info, onUpdate, onCreate }) => {
    const productInfo = getProductInfoByLang(info, language);
    const disabledField = !productInfo;
    const initialValues = {
        name: productInfo?.name,
        long_name: productInfo?.long_name,
        doc_name: productInfo?.doc_name,
    };
    const [descriptionText, setDescriptionText] = useState<string | undefined>(productInfo?.description);

    const handleUpdates = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        if (language) {
            if (productInfo) {
                onUpdate(allValues, language);
            } else {
                onCreate(allValues, language);
            }
        }
    });

    const handleChangeDescription = handleTextEditorChange((content: string): void => {
        if (language) {
            setDescriptionText(content);
            onUpdate({ description: content }, language);
        }
    });

    return initialValues ? (
        <Form layout={'vertical'} initialValues={initialValues} onValuesChange={handleUpdates}>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="name"
                               label="Назва"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="long_name"
                               label="Повна назва"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Input disabled={disabledField}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={10}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name="doc_name"
                               label="Назва для документів"
                               hasFeedback
                    >
                        <Input/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={16}>
                    <TextEditor value={descriptionText ?? ''} onChange={handleChangeDescription} disabled={disabledField} />
                </Col>
            </Row>
        </Form>
    ) : null;
};

export const productMainInfoForm = withLanguageTabs<IProductMainInfoForm>(ProductMainInfoForm);
