import { IEntryPoint, IRouteItem } from 'src/interfaces';
import { ROUTES } from 'src/constants';
import {
  PartnersBonusesPage, PartnersTestsPage
} from './pages';

const routeList: IRouteItem[] = [
  {
    path: ROUTES.ECONOMICAL_PARTNER_BONUS,
    exact: true,
    element: PartnersBonusesPage,
  },
  {
    path: ROUTES.ECONOMICAL_PARTNERS_TESTS,
    exact: true,
    element: PartnersTestsPage,
  },
];

export const EconomicalApp: IEntryPoint = {
  route: routeList,
};
