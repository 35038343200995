import React from 'react';
import { Select } from 'antd';
import { IUser } from 'main/interfaces';
import { OrderApi } from 'src/api';

const { Option } = Select;

interface IOrderGridManagerProps {
  order_id: number;
  users: IUser[];
  id_manager: number | undefined;
  onChange: (params?: Record<string, string | number | boolean | undefined>) => void;
}

export const OrderGridManager: React.FC<IOrderGridManagerProps> = ({ id_manager, users, order_id, onChange }) => {
  const handleChange = (value: number): void => {
    OrderApi.update(order_id, { id_manager: value }).then((res) => {
      onChange();
    });
  };

  return (
    <Select placeholder="Оберіть варіант із списку" value={id_manager} onChange={handleChange} style={{ width: '100%' }}>
      <Option value={undefined}>-</Option>
      {users?.map((user) => (
        <Option value={user.id} key={user.id}>
          {user?.info?.[0] ? user?.info[0]?.name : ''}
        </Option>
      ))}
    </Select>
  );
};
