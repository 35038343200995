import React from 'react';
import { Table } from 'src/base-component/Table/_Table';
import { Button, Input, Space, Switch, TableProps } from 'antd';
import { CopyFilled, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { IProduct } from 'main/interfaces';
import { ProductApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { Formatter } from 'src/helpers';

export const ProductGrid: React.FC = () => {
    const navigate = useNavigate();

    const handleEdit = (id: number): () => void => {
        return () => navigate(ROUTES.CATALOG_PRODUCTS + '/edit/' + id);
    };

    // const handleDelete = (id: number): () => void => {
    //     return () => {
    //         setLoading(true);
    //         ProductApi.delete(String(id)).then((res) => {
    //             if (res?.data?.success) {
    //                 getProducts();
    //             } else {
    //                 setLoading(false);
    //             }
    //         });
    //     };
    // };

    const handleCreate = (id: number, getProducts: (params?: Record<string, string | number | boolean | undefined>) => void): () => void => {
        return () => {
            ProductApi.copy(String(id)).then((res) => {
                if (res?.data?.id) {
                    getProducts();
                }
            });
        };
    };

    const handleActive = (id: number, is_active: boolean, getProducts: (params?: Record<string, string | number | boolean | undefined>) => void): () => void => {
        return () => {
            ProductApi.update(Number(id), { is_active }).then((res) => {
                if (res?.data?.id) {
                    getProducts();
                }
            });
        };
    };

    const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<IProduct>['columns'] => [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Назва',
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                if (record?.info) {
                    return record.info[0].name;
                }
                return null;
            }
        },
        {
            title: 'Модель',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Аліас',
            dataIndex: 'alias',
            key: 'alias',
            render: (_, record) => record?.alias?.length > 50 ? `${record.alias.slice(0, 50)}...` : record.alias
        },
        {
            title: 'Ціна',
            dataIndex: 'price',
            key: 'price',
            width: '100px',
            render: (_, record) => Formatter.toMoney(record.price),
        },
        {
            title: 'Ціна за одиницю',
            dataIndex: 'price_one',
            key: 'price_one',
            render: (_, record) => Formatter.toMoney(record.price_one),
        },
        {
            title: 'Активність',
            dataIndex: 'active',
            key: 'active',
            render: (_, record) => (
              <Switch checked={Boolean(record.is_active)} onChange={handleActive(record.id, !record.is_active, handleGetData)}/>
            ),
        },
        {
            title: 'Дії',
            key: 'action',
            render: (_, record) => (
              <Space size="middle">
                  <Button onClick={handleEdit(record.id)}>
                      <EditOutlined/>
                  </Button>
                  {/*<Button onClick={handleDelete(record.id)}>*/}
                  {/*    <DeleteOutlined />*/}
                  {/*</Button>*/}
                  <Button onClick={handleCreate(record.id, handleGetData)}>
                      <CopyFilled/>
                  </Button>
              </Space>
            ),
        },
    ];

    const filters = [
        {
            name: 'id',
            title: 'ID',
            field: <Input/>
        },
        {
            name: 'name',
            title: 'Назва товару',
            field: <Input/>
        },
        {
            name: 'model',
            title: 'Артикул',
            field: <Input/>
        },
    ];

    return (
        <Table getColumns={getColumns} getData={ProductApi.getAll.bind(ProductApi)} filtersTypes={filters} />
    );
};
