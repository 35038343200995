import React from 'react';
import { IOM_Order } from 'main/services/OrderManager';
import { Button, Col, Descriptions, Divider, Popover, Row, Space, Tooltip, Typography } from 'antd';
import { Formatter } from 'src/helpers';
import { ROUTES } from 'src/constants';
import { OrderStatus, OrderStatusForm } from '../';
import { CheckOutlined, TruckOutlined } from '@ant-design/icons';

interface IOrderMainDetailProps {
    order: IOM_Order;
    onChangeStatus: () => void;
    onCheckDeliveryStatus: () => void;
}

export const OrderMainDetail: React.FC<IOrderMainDetailProps> = ({ order, onChangeStatus, onCheckDeliveryStatus }) => {
    const handleChangeStatus = (): void => {
        onChangeStatus();
    };

    const handleCheckDeliveryStatus = (): void => {
        onCheckDeliveryStatus();
    };

    return (
        <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Descriptions title='Основні' size="middle">
                    <Descriptions.Item label='ID'>{order.id}</Descriptions.Item>
                    <Descriptions.Item label='Замовник'>
                        {order.id_customer
                            ? <a href={ROUTES.CUSTOMERS + '/edit/' + order.id_customer}>{order.customer_name}</a>
                            : order.customer_name
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label='Платник'>{order.payment_company ?? order.payment_client}</Descriptions.Item>
                    <Descriptions.Item label='Передзамовлення'>
                        {order.is_preorder ? <CheckOutlined /> : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label='Партнерське'>
                        {order.is_partner ? <CheckOutlined/> : '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label='Загальна вартість'>{Formatter.toMoney(order.total ?? 0, true) + ' грн'}</Descriptions.Item>
                    <Descriptions.Item label='Доставка'>{order.delivery_name}</Descriptions.Item>
                    <Descriptions.Item label='Оплата'>{order.payment_name}</Descriptions.Item>
                    <Descriptions.Item label='Дата створення'>{Formatter.toDate(order.createdAt)}</Descriptions.Item>
                    <Descriptions.Item label='Статус'>
                        <Popover content={<OrderStatusForm order_id={order.id} allowed_statuses={order.allowedStatuses ?? []} onChange={handleChangeStatus} />} trigger="click">
                            <Button type={'link'} style={{ padding: 0 }}>
                                <OrderStatus id={order.id_status} name={order.status_name ?? ''} />
                            </Button>
                        </Popover>
                    </Descriptions.Item>
                    <Descriptions.Item label='Email'><Typography.Paragraph copyable>{order.payment_customer_email}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label='Телефон'><Typography.Paragraph copyable>{order.payment_customer_phone}</Typography.Paragraph></Descriptions.Item>
                    <Descriptions.Item label='Коментар'>{order.comment}</Descriptions.Item>
                </Descriptions>
                <Divider/>
                <Descriptions title='Доставка'>
                    <Descriptions.Item label='Область'>{order.region}</Descriptions.Item>
                    <Descriptions.Item label='Місто'>{order.city}</Descriptions.Item>
                    {order.street && <Descriptions.Item label='Вулиця'>{order.street}</Descriptions.Item>}
                    {order.street_house && <Descriptions.Item label='Будинок'>{order.street_house}</Descriptions.Item>}
                    {order.np_warehouse && <Descriptions.Item label='Відділення Нової пошти'>{order.np_warehouse}</Descriptions.Item>}
                    {order.ttn && <Descriptions.Item label={'ТТН'} >
                                    <Space align={'center'}>
                                        <Typography.Paragraph copyable>{order.ttn}</Typography.Paragraph>
                                        <Tooltip title="Перевірити статус доставки">
                                            <Button type="primary"
                                                    shape="circle"
                                                    style={{ position: 'relative', top: '-9px' }}
                                                    onClick={handleCheckDeliveryStatus}
                                                    icon={<TruckOutlined/>}
                                            />
                                        </Tooltip>
                                    </Space>
                                  </Descriptions.Item>
                    }
                </Descriptions>
                <Divider/>
                {order.payment_company && (
                    <>
                        <Descriptions title='Дані платника (компанії)'>
                            <Descriptions.Item label='Назва юридичної особи'><Typography.Paragraph copyable>{order.payment_company}</Typography.Paragraph></Descriptions.Item>
                            <Descriptions.Item label='Код ЄДРПОУ'><Typography.Paragraph copyable>{order.payment_company_code}</Typography.Paragraph></Descriptions.Item>
                            <Descriptions.Item label='Адреса'><Typography.Paragraph copyable>{order.payment_company_address}</Typography.Paragraph></Descriptions.Item>
                            <Descriptions.Item label='Податковий код (номер)'><Typography.Paragraph copyable>{order.payment_company_code_vat}</Typography.Paragraph></Descriptions.Item>
                        </Descriptions>
                        <Divider/>
                    </>
                )}
            </Col>
        </Row>
    );
};
