import React, { useEffect } from 'react';
import { IProduct } from 'main/interfaces';
import { Button, Col, Form, InputNumber, Row, Select } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import { ApiBaseResponse, BaseApiPostData } from 'src/interfaces';
import { ProductApi } from 'src/api';

interface IOrderEditProductsAddProps {
    products: IProduct[];
    onSearch: (value: string) => void;
    onAdd: (product: BaseApiPostData) => Promise<ApiBaseResponse<Record<string, string>> | undefined>;
}

export const OrderEditProductsAdd: React.FC<IOrderEditProductsAddProps> = ({ products, onSearch, onAdd }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = React.useState<boolean>(false);
    const idProduct = Form.useWatch('id_product', form);

    const productOptions = products.map((product) => ({
        value: product.id,
        label: product?.info?.[0]
            ? product.model + ' | ' + product.info?.[0].name
            : ''
    }));

    const handleSearch = (value: string):void => {
        onSearch(value);
    };

    const handleFinish = (values: Record<string, string | boolean | number>): void => {
        setLoading(true);
        onAdd(values).then((res) => {
            if (res?.status) form.resetFields();
            setLoading(false);
        });
    };

    useEffect(() => {
        if (idProduct) {
            ProductApi.getPrice(idProduct).then((res) => {
                form.setFieldValue('price', res?.data?.price);
            });
        }
    }, [form, idProduct]);

    return (
        <Form
            name="validate_other"
            onFinish={handleFinish}
            form={form}
            layout={'horizontal'}
            disabled={loading}
            initialValues={{
                count: 1
            }}
        >
            <Row gutter={15}>
                <Col span={13}>
                    <Form.Item label="Товар"
                               name={'id_product'}
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <Select
                            showSearch
                            placeholder=""
                            filterOption={(value, option) => option?.label.includes(value) ?? false}
                            onSearch={handleSearch}
                            options={productOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'count'}
                               label="Кількість"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'шт.'} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item name={'price'}
                               label="Ціна"
                               rules={[{ required: true, message: 'Поле повинно бути заповнене' }]}
                               hasFeedback
                    >
                        <InputNumber addonAfter={'грн.'} />
                    </Form.Item>
                </Col>
                <Col span={3}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <PlusCircleFilled/>
                            Додати
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
