import React from 'react';
import { Menu as AntMenu } from 'antd';
import { LAYOUT_BACKGROUND_COLOR, MENU_KEYS, ROUTES } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import {
    AuditOutlined,
    PartitionOutlined, SettingOutlined, ShopOutlined,
    UsergroupAddOutlined, WalletOutlined
} from '@ant-design/icons';

export const Menu: React.FC = () => {
    const navigate = useNavigate();

    return (
        <AntMenu
            style={{ background: LAYOUT_BACKGROUND_COLOR }}
            mode="inline"
            items={[
                {
                    key: MENU_KEYS.CATALOG,
                    icon: <PartitionOutlined />,
                    label: 'Каталог',
                    children: [
                        {
                            key: ROUTES.CATALOG_PRODUCTS,
                            label: 'Продукти',
                            onClick: ({ key }) => navigate(key),
                        },
                        {
                            key: ROUTES.CATALOG_CATEGORIES,
                            label: 'Категорії',
                            onClick: ({ key }) => navigate(key),
                        },
                        {
                            key: ROUTES.CATALOG_TAG,
                            label: 'Теги',
                            onClick: ({ key }) => navigate(key),
                        },
                        {
                            key: ROUTES.CATALOG_ATTRIBUTES,
                            label: 'Атрибути',
                            onClick: ({ key }) => navigate(key),
                        }
                    ]
                },
                {
                    key: MENU_KEYS.ORDERS,
                    icon: <AuditOutlined />,
                    label: 'Замовлення',
                    onClick: ({ key }) => navigate(key),
                },
                {
                    key: MENU_KEYS.CUSTOMERS,
                    icon: <UsergroupAddOutlined />,
                    label: 'Клієнти',
                    onClick: ({ key }) => navigate(key),
                },
                {
                    key: MENU_KEYS.SETTINGS,
                    icon: <SettingOutlined />,
                    label: 'Налаштування',
                    children: [
                        {
                            key: ROUTES.SETTINGS_RELATED_PRODUCTS,
                            label: 'Рекомендовані продукти',
                            onClick: ({ key }) => navigate(key),
                        },
                    ]
                },
                {
                    key: MENU_KEYS.ECONOMICAL,
                    icon: <WalletOutlined />,
                    label: 'Бухгалтерія',
                    children: [
                        {
                            key: ROUTES.ECONOMICAL_PARTNER_BONUS,
                            label: 'Виплата бонусів',
                            onClick: ({ key }) => navigate(key),
                        },
                        {
                            key: ROUTES.ECONOMICAL_PARTNERS_TESTS,
                            label: 'Тестування партнерів',
                            onClick: ({ key }) => navigate(key),
                        },
                    ]
                },
                {
                    key: MENU_KEYS.WAREHOUSES_MAIN,
                    icon: <ShopOutlined />,
                    label: 'Склад',
                    children: [
                        {
                            key: ROUTES.WAREHOUSES,
                            label: 'Склади',
                            onClick: ({ key }) => navigate(key),
                        },
                        {
                            key: ROUTES.WAREHOUSES_ACTIONS,
                            label: 'Рух на складах',
                            onClick: ({ key }) => navigate(key),
                        },
                    ]
                },
            ]}
            // defaultSelectedKeys={[MENU_KEYS.CATALOG]}
        />
    );
};
