import React from 'react';
import { Table } from 'src/base-component';
import { TableProps } from 'antd';
import { ICustomerPartnerProductSale } from 'main/interfaces';
import { Formatter } from 'src/helpers';

export interface IPartnersTestsProps {
  customer_order_products: ICustomerPartnerProductSale[];
}

export const PartnersTestsProducts: React.FC<IPartnersTestsProps> = ({ customer_order_products }) => {
  const columns: TableProps<ICustomerPartnerProductSale>['columns'] = [
    {
      title: 'Продукт',
      key: 'product_name',
      render: (_, record) => record?.product?.info ? record?.product?.info[0]?.name : '',
    },
    {
      title: 'Модель',
      key: 'product_model',
      render: (_, record) => record?.product?.model ?? '',
    },
    {
      title: 'Ціна',
      key: 'price',
      render: (_, record) => Formatter.toMoney(record?.price ?? ''),
    },
    {
      title: 'Бонус',
      key: 'bonus',
      render: (_, record) => Formatter.toMoney(record?.bonus ?? ''),
    },
    {
      title: 'Кількість',
      dataIndex: 'count',
      key: 'count',
    },
  ];

  return <Table columns={columns} data={customer_order_products} loading={false}/>;
};
