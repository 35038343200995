import { BaseApi } from '.';
import { ApiBaseResponse, ICounters } from 'src/interfaces';

class DashboardApi extends BaseApi {
  constructor() {
    super('/dashboard');
  }

  async getCounters(): Promise<ApiBaseResponse<ICounters> | undefined> {
    return this.errorHandler((await this.GET<ApiBaseResponse<ICounters>>('/order-data'))?.data);
  }
}

export const dashboardApi = new DashboardApi();
