import React from 'react';
import { Table } from 'src/base-component/Table/_Table';
import { Button, Input, Space, Switch, TableProps } from 'antd';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { CustomerApi } from 'src/api';
import { ROUTES } from 'src/constants';
import { Formatter } from 'src/helpers';
import { ICustomer } from 'main/interfaces';

export const CustomerGrid: React.FC = () => {
  const navigate = useNavigate();

  const handleEdit = (id: number): () => void => {
    return () => navigate(ROUTES.CUSTOMERS + '/edit/' + id);
  };

  const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<ICustomer>['columns'] => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
    },
    {
      title: 'ПІП',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info ? `${record?.info?.surname} ${record?.info?.name} ${record?.info?.middle_name ?? ''}` : '',
    },
    {
      title: 'Email',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info?.email ?? '-'
    },
    {
      title: 'Телефон',
      // dataIndex: 'name',
      key: 'name',
      render: (_, record) => record?.info?.phone ?? '-'
    },
    {
      title: 'Партнер',
      dataIndex: 'partner',
      key: 'partner',
      render: (_, record) => record?.partner ? <CheckCircleFilled style={{ fontSize: '19px', color: 'lightgreen' }}/> : ''
    },
    {
      title: 'Активний',
      dataIndex: 'active',
      key: 'active',
      render: (_, record) => record?.active ? <CheckCircleFilled style={{ fontSize: '19px', color: 'lightgreen' }}/> : ''
    },
    {
      title: 'Дата реєстрації',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
    {
      title: 'Дії',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={handleEdit(record.id)}>
            <EditOutlined/>
          </Button>
        </Space>
      ),
    },
  ];

  const filters = [
    {
      name: 'name',
      title: 'Прізвище або імʼя',
      field: <Input />
    },
    {
      name: 'phone',
      title: 'Телефон',
      field: <Input/>
    },
    {
      name: 'partner',
      title: 'Показати тільки партнерів',
      field: <Switch />
    }
  ];

  return (
    <Table getColumns={getColumns} getData={CustomerApi.getClients.bind(CustomerApi)} filtersTypes={filters}/>
  );
};

