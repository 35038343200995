import React from 'react';
import { PageHeader } from 'src/base-component';
import { Breadcrumb } from 'antd';
import { PartnersTests } from '../components';

export const PartnersTestsPage: React.FC = () => {
  return (
    <>
      <PageHeader title={'Тестування партнерів'}
                  subtitle={'Усі тестування партнерів'}
                  breadcrumbs={
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <a href="/">Головна</a>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        Тестування партнерів
                      </Breadcrumb.Item>
                    </Breadcrumb>
                  }
      />
      <PartnersTests/>
    </>
  );
};
