import React, { ReactNode } from 'react';
import { ICustomerPartnerOrder } from 'main/interfaces';
import { CustomerApi } from 'src/api';
import { TableProps } from 'antd';
import { Formatter } from 'src/helpers';
import { Table } from 'src/base-component/Table/_Table';
import { PartnersTestsProducts } from './PartnersTestsProducts';
import { Link } from 'react-router-dom';

export const PartnersTests: React.FC = () => {
  const getColumns = (handleGetData: (params?: Record<string, string | number | boolean | undefined>) => void): TableProps<ICustomerPartnerOrder>['columns'] => [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '№ Тестування',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Клієнт',
      dataIndex: 'id_customer',
      key: 'id_customer',
      render: (_, record) => <Link to={'/customers/edit/' + record.id_customer}>{record?.customer?.info?.surname} {record?.customer?.info?.name}</Link>,
    },
    {
      title: 'Сума',
      dataIndex: 'amount',
      key: 'amount',
      render: (_, record) => Formatter.toMoney(record?.amount ?? '', true) + ' ' + 'грн',
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => Formatter.toDate(record?.createdAt ?? ''),
    },
  ];

  const handleExpandable = (record: ICustomerPartnerOrder): ReactNode => {
    return <PartnersTestsProducts customer_order_products={record?.order_products ?? []} />;
  };

  return (
    <Table getColumns={getColumns} getData={CustomerApi.getPartnersOrders.bind(CustomerApi)} onExpandable={handleExpandable} />
  );
};
