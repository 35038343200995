import React from 'react';
import { IOM_Info } from 'main/services/OrderManager';
import { BaseApiPostData, FormChanges } from 'src/interfaces';
import { handleFormChanges } from 'src/helpers';
import { Col, Form, Row, Select, Switch } from 'antd';
import { ICustomer, IDelivery, IPayment } from 'main/interfaces';

const { Option } = Select;

interface IOrderEditMainProps {
    order: IOM_Info;
    customers: ICustomer[];
    deliveries: IDelivery[];
    payments: IPayment[];
    loading: boolean;
    onChange: (values: BaseApiPostData) => void;
}

export const OrderEditMain: React.FC<IOrderEditMainProps> = ({ order, customers, payments, deliveries, loading, onChange }) => {
    const handleChange = handleFormChanges((changedValues: FormChanges, allValues: FormChanges) => {
        onChange(allValues);
    });
    return (
        <Form layout={'vertical'} onValuesChange={handleChange} initialValues={order} disabled={loading}>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'id_delivery'}
                               label="Тип доставки"
                               hasFeedback
                    >
                        <Select placeholder="Оберіть варіант із списку">
                            {deliveries?.map((delivery) => (
                                <Option value={delivery.id} key={delivery.id}>
                                    {delivery?.info?.[0]?.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'is_preorder'}
                               label="Передзамовлення"
                               hasFeedback
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'id_payment'}
                               label="Тип оплати"
                               hasFeedback
                    >
                        <Select placeholder="Оберіть варіант із списку">
                            {payments?.map((payment) => (
                                <Option value={payment.id} key={payment.id}>
                                    {payment?.info?.[0]?.value}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'is_partner'}
                               label="Партнерське"
                               hasFeedback
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={30}>
                <Col xs={24} sm={24} md={24} lg={8}>
                    <Form.Item name={'id_customer'}
                               label="Клієнт"
                               hasFeedback
                    >
                        <Select placeholder="Оберіть варіант із списку">
                            {customers?.map((customer) => (
                                <Option value={customer.id} key={customer.id}>
                                    {customer?.info?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
