import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Statistic } from 'antd';
import CatLook1 from 'src/assets/images/cat-look-1.png';
import CatLook2 from 'src/assets/images/cat-look-2.jpg';
import CatLook3 from 'src/assets/images/cat-look-3.png';
import CatLook4 from 'src/assets/images/cat-look-4.jpg';
import CatLook5 from 'src/assets/images/cat-look-5.png';
import CatLook6 from 'src/assets/images/cat-look-6.png';
import CatLook7 from 'src/assets/images/cat-look-7.png';
import CatLook9 from 'src/assets/images/cat-look-9.png';
import { DashboardApi } from 'src/api';

const countersLabels: Record<string, string> = {
  orderCountFinished: 'Завершених замовлень',
  orderCountCanceled: 'Відмінених замовлень',
  orderTotal: 'Сума завершених замовлень',
  orderTotalDiscount: 'Сума знижок завершених замовлень',
  customerCount: 'Кількість клієнтів',
  customerPartnerCount: 'Кількість партенрів',
  customerPartnerTestingCount: 'Кількість тестувань',
  customerPartnerTestingTotal: 'Сума тестувань',
  customerPartnerTestingTotalBonus: 'Сума бонусів',
  orderTotalProduct: 'Кількість проданих упаковок',
};

export const StartPage: React.FC = () => {
  const rowStyles = { margin: '10px', boxShadow: '0px 7px 19px lightgray', padding: '37px 10px' };
  const colStyles = { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexFlow: 'column' };
  const [counters, setCounters] = useState<{label: string, value: number}[]>();
  const [loading, setLoading] = useState(true);

  const cats = [
    CatLook1,
    CatLook2,
    CatLook3,
    CatLook4,
    CatLook5,
    CatLook6,
    CatLook7,
    CatLook9,
  ];

  useEffect(() => {
    if (counters === undefined) {
      setLoading(true);
      DashboardApi.getCounters().then((res) => {
        res?.data && setCounters(Object.entries(res?.data ?? {}).map(([label, value]) => ({
          label: countersLabels[label] ?? '',
          value: Number(value),
        })));
        setLoading(false);
      });
    }
  }, [counters]);

  return (
    <>
      <Row gutter={16} style={rowStyles}>
        {counters?.slice(0, 4).map(({ label, value }, index) => (
          <Col span={6} style={colStyles} key={label}>
            <Statistic title={label} value={value} formatter={(value) => value} loading={loading} />
            <Image src={cats[index]} height={147} alt={label} preview={false} />
          </Col>
        ))}
      </Row>
      <Row gutter={16} style={{ ...rowStyles, margin: '37px 10px 10px 10px' }}>
        {counters?.slice(4, 8).map(({ label, value }, index) => (
          <Col span={6} style={colStyles} key={label}>
            <Statistic title={label} value={value} loading={loading} />
            <Image src={cats[4 + index]} height={147} alt={label} preview={false} />
          </Col>
        ))}
      </Row>
    </>
    // <InProgress />
  );
};
